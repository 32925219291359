
const Container = ({children, padding}) => {

const returnPadding = () => {
    switch (padding) { 
        case "onlyPaddingLeft": 
            return 'paddingLeft noPaddingMobile'
        case "onlyPaddingRight": 
            return 'paddingRight noPaddingMobile'
        // case "mobileBanner":
        //     return 'paddingLeft noPaddingMobile'
        default: return 'div'
    } 
}
    return (
        <div className={returnPadding()}>
            {children}
        </div>
    )
}

export default Container

