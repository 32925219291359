import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { Home, Contato, SobreNos, FabricacaoDeCompostos, Revestimento, Pisos, Tapetes, Guarnicoes, NotFound } from "../pages"
import { FooterComponent as Footer, Navbar, ScrollTop } from "../components"

const RouterWrapper = () => {
    return (
        <Router>
            <Navbar />
            <ScrollTop>
                <Routes>
                    <Route exact sensitive path="/" element={<Home />} />
                    <Route path="/contato" element={<Contato />} />
                    <Route path="/sobre-nos" element={<SobreNos />} />
                    <Route path="/produtos/fabricacao-de-compostos" element={<FabricacaoDeCompostos />} />
                    <Route path="/produtos/revestimento-acustico-e-termico" element={<Revestimento />} />
                    <Route path="/produtos/pisos-e-revestimentos-emborrachados" element={<Pisos />} />
                    <Route path="/produtos/tapetes-automotivos" element={<Tapetes />} />
                    <Route path="/produtos/guarnicoes-epdm-e-silicone" element={<Guarnicoes />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </ScrollTop>
            <Footer />
        </Router>
    )
}

export default RouterWrapper