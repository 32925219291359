import { Container, ProductContent } from "../../components"
import { Row, Col, Image } from 'antd'
import img1 from '../../assets/img/tapetes/tap1.png'
import img2 from '../../assets/img/tapetes/tap2.png'
import img3 from '../../assets/img/tapetes/tap3.png'
import img4 from '../../assets/img/tapetes/tap4.png'

const Tapetes = () => {
    const images = [img1, img2, img3, img4]
    return (
        <section className="mb-100" >
            <div className="breadcrumb">
                <p>Produtos</p>
                <hr/>
            </div>

            <Container>
                <Row className="flow-column-reverse-mobile">
                    <Col xl={12} lg={12} md={24} className="imagesWrap imagesWrap2">
                       {images.map(i => (
                        <Image 
                            src={i} 
                            alt="Tapetes automotivos" 
                            preview={{
                                mask: (<label></label>),
                            }}
                        />
                       ))}
                    </Col>
                    <Col md={1}/>
                    <Col xl={11} lg={11}>
                        <ProductContent 
                            title="Tapetes automotivos" 
                            text="Com a maior e mais completa linha do mercado, conte conosco para encontrar os produtos ideais para seu negócio." 
                            fullContentBtn
                            link='/Catalogo_CarFloor_2022_FINAL.pdf'
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Tapetes 