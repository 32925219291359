import { useLocation } from "react-router"
import { useEffect } from "react"

const ScrollTop = (props) => {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0,0)
    }, [location])

    return <>{props.children}</>
}

export default ScrollTop