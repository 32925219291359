import { Col, Row, Space, Form, Input, Button, Alert } from "antd"
import { Container } from "../../components"
import banner from '../../assets/img/banner.png'
import icon1 from '../../assets/img/icons/icon1.svg'
import icon2 from '../../assets/img/icons/icon2.svg'
import icon3 from '../../assets/img/icons/icon3.svg'
import icon4 from '../../assets/img/icons/icon4.svg'
import icon5 from '../../assets/img/icons/icon5.svg'
import certification1 from '../../assets/img/certifications/certification1.png'
import certification2 from '../../assets/img/certifications/certification2.png'
import certification3 from '../../assets/img/certifications/certification3.png'
import certification4 from '../../assets/img/certifications/certification4.png'
import { useNavigate } from "react-router"
import ProductCard from "./productCard/productCard"
import { useState } from "react"
import axios from "axios"
import FormData from "form-data"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Home = () => {

    const [submiting, setSubmiting] = useState(false)
    const [status, setStatus] = useState('UNSET')
    const [form] = Form.useForm()

    const emailValidator = (_, value) => {
        if (!value || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('E-mail inválido'));
    };

    const onSubmitNewsletter = async (values) => {
        setSubmiting(true)
        let data = new FormData()
        data.append('name', values.name)
        data.append('email', values.email)
        data.append('formType', 'newsletter')

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://daudborrachas.com.br/mail.php',
            headers: { 
              ...data.getHeaders
            },
            data : data
          };
          
          axios.request(config)
          .then((response) => {
            setStatus('SUCCESS')
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            setStatus('ERROR')
            console.log(error);
          });

          setSubmiting(false)
    }

    const products = [
        {
            icon: icon1,
            title: 'Fabricação de composto',
            link: 'fabricacao-de-compostos'
        },
        {
            icon: icon2,
            title: 'Tapetes automotivos',
            link: 'tapetes-automotivos'
        },
        {
            icon: icon5,
            title: 'Guarnições EPDM e silicone',
            link: 'guarnicoes-epdm-e-silicone'
        },
        {
            icon: icon3,
            title: 'Pisos e revestimentos emborrachados',
            link: 'pisos-e-revestimentos-emborrachados',
            mobileWidth50: true,
        },
        {
            icon: icon4,
            title: 'Revestimento acústico e térmico',
            link: 'revestimento-acustico-e-termico',
            mobileWidth50: true,
        },
        
    ]

    const certifications = [certification1, certification2, certification3, certification4]

    const navigate = useNavigate()
    const goToAbout = () => {
        navigate('/sobre-nos')
    }

    return (
        <>
            <Container padding='onlyPaddingLeft'>
                <Row justify='center'>
                    <Col xl={10} lg={11} md={12} xs={24} className="d-flex flow-column justify-center banner-mobile">
                        {/* <Space direction="vertical" align="center" className="w-100"> */}
                            <hr className="hr mb-25"/>
                            <h1>Excelência em qualidade e inovação desde 1935</h1>
                            <h4>Uma completa linha de soluções em borracha com qualidade garantida e certificada em todos os processos</h4>
                        {/* </Space> */}
                    </Col>
                    <Col xl={2} lg={1} md={1} xs={0}/>
                    <Col xl={12} lg={12} md={11} xs={24} className='teste'>
                        <img src={banner} alt='Vista de cima da fábrica Daud ' className='d-none-mobile'/>
                    </Col>
                </Row>
            </Container>

            <section id="produtos" className='productsSection z-2'>
                <div className='triangulo'/>
                <div className='retangulo'>
                    <Container className='onlyPaddingRight'>
                        <Row>
                            <h2 className='productsTitle'>Nossos produtos</h2>
                        </Row>
                        <Row>
                            <Col xl={24} className='productsWrap'>
                                {products.map((product) => (
                                    <ProductCard product={product} />
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            <section>
                <Container padding='onlyPaddingRight'>
                    <Row className='aboutContent'>
                        <Col xl={11} md={11} xs={24}>
                            {/* <img src={banner} alt='Vista de cima da fábrica Daud' className="factoryImg"/> */}
                            <iframe  className="factoryImg" src="https://www.youtube.com/embed/Wr25xrMbjmU?start=3" title="Video da Daud Borrachas" frameborder="0"  allowfullscreen></iframe>
                        </Col>
                        <Col xl={1} md={1} xs={24}/>
                        <Col xl={12} md={12} xs={24}>
                            <h2 className="align-center-mobile">Sobre nós</h2>
                            <p className="align-center-mobile">
                                Desde 1935 a Daud Borrachas tem se especializado em apresentar soluções em borracha para a indústria automobilística (tapetes, forrações, mangueiras e guarnições) e de construção civil (pisos, acessórios e perfis)
                                <br/><br/>
                                Nosso foco é apresentar soluções aos nossos clientes prezando sempre pela máxima qualidade e excelência no atendimento a nossos parceiros.
                            </p>
                            <Space direction="vertical" align="center" className="w-100">
                                <Space align="center" size="large" className="mt-25" >
                                
                                    {certifications.map((certification) => (
                                        <img src={certification} alt="Selo de certificação" />
                                    ))}
                                </Space>
                                <Space align="center" className="mt-25" >
                                    <Button className="button" type="primary" onClick={goToAbout}>
                                        Saber mais
                                    </Button>
                                </Space>
                            </Space>
                            
                        </Col>
                    </Row>
                </Container>
            </section>
            
            {/* <section >
                <Container>
                    <Row className='businessSection'>
                        <p>Confiam em nós</p>
                        
                    </Row>
                </Container>
            </section> */}

            <section id="news" className='contactSection'>
                <Container>
                    <Row className='contactContent'>
                        <Col xl={24}>
                            <Row justify='center'>
                                <Col xl={24}>
                                    <h2>Deixe seu número e nossa equipe especializada entrará em contato para oferecer soluções sob medida</h2>
                                </Col>
                            </Row>
                            <Row justify='center'>
                                <Col xl={24} justify='center'>
                                    {status === 'ERROR' &&
                                        <Alert message="Ops, algo deu errado. Tente novamente." type="error" />
                                    }
                                    {status !== 'SUCCESS' && 
                                    <Form form={form} onFinish={onSubmitNewsletter}>
                                        <Row gutter={{ xs: 5, sm:5, md: 5, lg: 10, xl: 15 }}>
                                            <Col xl={12} lg={12} md={12} xs={24}>
                                                <Form.Item
                                                    name="name"
                                                    rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                                                >
                                                    <Input placeholder="Primeiro nome" />
                                                </Form.Item>
                                            </Col>
                                            <Col xl={12} lg={12} md={12} xs={24}>
                                                <Form.Item
                                                    name="email"
                                                    rules={[{ required: true, message: 'E-mail inválido ou não preenchido', validator: emailValidator }]}
                                                >
                                                    <Input placeholder="E-mail" />
                                                </Form.Item>
                                            </Col>
                                            <Col xl={24} lg={24} md={24} xs={24}>
                                                <Form.Item className="d-flex justify-center">
                                                    {submiting ? 
                                                        <Spin indicator={
                                                            <LoadingOutlined
                                                                style={{ fontSize: 24, }} spin
                                                            />} /> : 
                                                    <Button className="button" type="primary" htmlType="submit" >
                                                        Enviar
                                                    </Button> }
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form> }
                                    {status === 'SUCCESS' && 
                                        <center><Alert message="Obrigado por se cadastrar em nossa newsletter!" type="success"
                                          style={{ width: '50%', textAlign:'center' }}
                                        /></center>
                                    }
                                </Col>
                            </Row>                        
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Home