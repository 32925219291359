import { Col, Row, Form, Input, Button, Alert } from "antd"
import { Container } from "../../components"
import bgContato from '../../assets/img/bg-contato.png'
import axios from "axios"
import FormData from "form-data"
import { useEffect, useState } from "react"

const Contato = () => {
    const [form] = Form.useForm()
    const [submiting, setSubmitting] = useState(false)
    const [status, setStatus] = useState('UNSET')

    const onContactSubmit = async (values) => {
        setSubmitting(true);
        try {

            let data = new FormData()
            
            data.append('name', values.name)
            data.append('phone', values.phone)
            data.append('email', values.email)
            data.append('subject', values.subject)
            data.append('message', values.message)
            data.append('formType', 'contact')
    
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://daudborrachas.com.br/mail.php',
                headers: { 
                  ...data.getHeaders
                },
                data : data
            };
      
            await axios.request(config);
      
            // After successful submission, you can handle the success state here.
            setStatus('SUCCESS');
        } catch (error) {
            // Handle errors here
            setStatus('ERROR');
            console.log(error);
        } finally {
            // Ensure that the submit button is re-enabled in all cases
            setSubmitting(false);
        }
    };
      
    const emailValidator = (_, value) => {
        if (!value || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('E-mail inválido'));
    };
    

    return (
        <>
        <Row id="contato" >
            <Col xl={11} lg={9} md={24} sm={24} xs={24}>
                <img src={bgContato} alt="Imagem de uma pilha de borrachas pretas" className="contactImg"/>
            </Col>
            <Col md={1}/>
            <Col xl={12} lg={14} md={24} sm={24} xs={24} className="d-flex align-center mt-50 mb-50 responsivePadding">
                <Container padding="onlyPaddingRight">
                    <Row >
                        <Col xl={24}>
                            <h2>Entre em contato</h2>
                            {status === 'ERROR' &&
                                <Alert message="Ops, algo deu errado. Tente novamente." type="error" />
                            }
                            {status !== 'SUCCESS' &&
                            <Form form={form} onFinish={onContactSubmit}>
                                <Row gutter={{ xs: 5, sm:5, md: 5, lg: 10, xl: 15 }}>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                        <Form.Item
                                            name="name"
                                            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                                        >
                                            <Input placeholder="Nome" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                        <Form.Item
                                            name="phone"
                                            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                                        >
                                            <Input placeholder="Celular" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: 'E-mail inválido ou não preenchido', validator: emailValidator }]}
                                        >
                                            <Input placeholder="E-mail" type="email" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                        <Form.Item
                                            name="subject"
                                        >
                                            <Input placeholder="Assunto" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Form.Item 
                                            name={'message'}
                                            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                                        >
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24} className="d-flex justify-end">
                                        <Form.Item>
                                            <Button loading={submiting} disabled={submiting} className="button" type="primary" htmlType="submit" >
                                                Enviar
                                            </Button> 
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>}
                            {status === 'SUCCESS' && 
                                <center><Alert message="Obrigado pelo contato. Em breve, nossa equipe responderá sua solicitação." type="success"
                                    style={{textAlign:'center' }}
                                /></center>
                            }
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        </> 
    )
}

export default Contato