import { Col, Layout, Row} from 'antd'
import logo from '../../assets/img/logo-footer.png';
import { Container } from '../../components'

const { Footer } = Layout;

const FooterComponent = () => {
    const date = new Date()
    const currentYear = date.getFullYear()

    const pages = [
        {
            label: 'Home',
            url: '/'
        },
        {
            label: 'Produtos',
            url: '/#produtos'
        }, 
        {
            label: 'Sobre nós',
            url: '/sobre-nos'
        },
        {
            label: 'Contato',
            url: '/contato'
        }
    ]

    return (
        <Footer className='footer'>
            <Container>
                <Row>
                    <Col xl={10} md={6} xs={24} >
                        <a href="/"><img src={logo} alt="Logo Daud Guarnições" /></a>
                    </Col>
                    <Col xl={7} md={9} xs={24}>
                        <h4 className='tx-white tx-bold mb-10'>Páginas</h4>
                        <Row>
                            {pages.map(page => (
                                <Col xs={12}>
                                    <a href={page.url} className='tx-white'>{page.label}</a><br />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col xl={7} md={9} xs={24}>
                        <h4 className='tx-white tx-bold mb-10'>Fale com a gente</h4>
                        <a href="mailto:contatosite@daudborrachas.com.br" className='tx-white'>contatosite@daudborrachas.com.br</a><br />
                        <a href="phone:+551935729260" className='tx-white'>+55 19 3572-9260</a>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <hr />
                        <h6 className='tx-white tx-center'>&copy; {currentYear} Todos os direitos reservados</h6>
                    </Col>
                </Row>
            </Container>
        </Footer>
    )
}

export default FooterComponent