import { Image } from 'antd'
import { useEffect, useState } from 'react'

const Perfis = () => {
    const [images, setImages] = useState([])

    useEffect(() => {
        const importImages = async () => {
            const importedImages = []

            for(let i = 1; i <= 60; i ++) {
                const image = await import (`../../../assets/img/perfis/perfil (${i}).jpg`);
                importedImages.push(image.default)
            }

            setImages(importedImages)
        }

        importImages()
    }, [])

    return (
        <div className='perfisWrapper'>
            {images.map((src, index) => (
                <Image 
                    key={index} src={src} 
                    alt="Imagem do produto" 
                    preview={{
                        mask: (<label></label>),
                    }}
                />
            ))}
        </div>
    )
}

export default Perfis 