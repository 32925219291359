import { Col, Row, Button } from "antd"
import { useNavigate } from "react-router"
import { Container } from "../../components"


const NotFound = () => {
    const navigate = useNavigate()
    const onClick = () => {
        navigate('/')
    }

    return (
        <Container>
            <Row id="contato" className="d-flex flow-column justify-center align-center">
                <Col  className="d-flex flow-column justify-center align-center notFoundContent">
                    <h1>404</h1>
                    <h2>Página não encontrada</h2>
                    <Button onClick={onClick} type="primary" className="button">Acessar Home</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default NotFound