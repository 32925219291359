import { Container, ProductContent } from "../../components"
import { Row, Col, Image } from 'antd'
import img1 from '../../assets/img/pisos/piso1.png'
import img2 from '../../assets/img/pisos/piso2.png'
import img3 from '../../assets/img/pisos/piso3.png'
import img4 from '../../assets/img/pisos/piso4.png'
import img5 from '../../assets/img/pisos/piso5.png'
import img6 from '../../assets/img/pisos/piso6.png'
import img7 from '../../assets/img/pisos/piso7.png'
import img8 from '../../assets/img/pisos/piso8.png'

const Pisos = () => {
    const images = [img1, img2, img3, img4, img5, img6, img7, img8]
    return (
        <section className="mb-100" >
            <div className="breadcrumb">
                <p>Produtos</p>
                <hr/>
            </div>

            <Container>
                <Row className="flow-column-reverse-mobile">
                    <Col xl={12} lg={12} md={24} className="imagesWrap imagesWrap4">
                        {images.map(i => (
                            <Image 
                                src={i} 
                                alt="Imagens de produtos de pisos e revestimentos emborrachados" 
                                preview={{
                                    mask: (<label></label>),
                                }}
                            />
                        ))}
                    </Col>
                    <Col md={1}/>
                    <Col xl={11} lg={11}>
                        <ProductContent 
                            title="Pisos e revestimentos emborrachados" 
                            text="Pisos emborrachados para diversas aplicações" 
                            fullContentBtn
                            link='http://www.daud.com.br/index.html'
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Pisos 