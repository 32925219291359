import { Layout, Menu, Col, Row, Button } from 'antd';
import logo from '../../assets/img/logo.png'
import { useState } from 'react';
import menu from '../../assets/img/icons/menu.svg'

const { Header } = Layout;

const Navbar = () => {
    const [showMenu, setShowMenu] = useState('menu-disabled')
    const [subMenuHeight, setSubMenuHeight] = useState(0)

    const handleToggleMenu = () => {
        const menuClass = showMenu === 'menu-disabled' ? 'menu-enabled' : 'menu-disabled'

        setShowMenu(menuClass)
    }

    const openSubMenu = () => {
        const height = subMenuHeight === 0 ? 250 : 0

        setSubMenuHeight(height)
    }

    const products = [
        {
            label: 'Fabricação de compostos',
            key: 'fabricacao-de-compostos'
        }, {
            label: 'Revestimento acústico e térmico',
            key: 'revestimento-acustico-e-termico'
        }, {
            label: 'Pisos e revestimentos emborrachados',
            key: 'pisos-e-revestimentos-emborrachados'
        }, {
            label: 'Tapetes automotivos',
            key: 'tapetes-automotivos'
        }, {
            label: 'Guarnições EPDM e Silicone',
            key: 'guarnicoes-epdm-e-silicone'
        }
    ]

    const pages = [
        {
            label: 'Home',
            key: '/'
        }, {
            label: 'Sobre nós',
            key: '/sobre-nos'
        }, {
            label: 'Nossos produtos',
            key: '/nossos-produtos',
        }, {
            label: 'Contato',
            key: '/contato'
        }
    ]

    return (
        <Header className='z-2 p-relative'>
                <Row>
                    <Col xl={24} md={24} xs={24} style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <a href="/" style={{flex: 1}}><img src={logo} alt='Logo Daud Guarnições' className='logo'/></a>
                        <ul style={{ flex: 2}} className={['menu', showMenu].join(' ')}>
                            {pages.map((page, i) => (
                                <li className={i === 2 && 'sub-menu-parent'} style={{zIndex: i === 2 ? 3 : 2}}>
                                    {i === 2 ? <label onClick={openSubMenu}>{page.label}</label> :
                                    <a href={page.key}>{page.label}</a>}
                                    {i === 2 && 
                                        (
                                            <ul className='sub-menu' style={{height: `${subMenuHeight}px`, display: subMenuHeight === 0 ? 'none' : 'block'}}>
                                                {products.map(product => (
                                                    <li><a href={`/produtos/${product.key}`}>{product.label}</a></li>
                                                ))}
                                            </ul>
                                        )
                                    }
                                </li>
                            ))}
                        </ul>
                        <label style={{flex: 1, textAlign: 'right'}} className='phoneNav'>+55 19 3572-9260</label>
                    </Col>
                    <Button className='toggle-menu' onClick={handleToggleMenu}>
                        <img src={menu} alt='Ícone para expandir o menu' />
                    </Button>
                    
                </Row>
        </Header>
      );
}

export default Navbar