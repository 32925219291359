import { Container, ProductContent } from "../../components"
import { Row, Col, Image } from 'antd'
import product from '../../assets/img/product.png'

const FabricacaoDeCompostos = () => {
    return (
        <section className="mb-100" >
            <div className="breadcrumb">
                <p>Produtos</p>
                <hr/>
            </div>

            <Container>
                <Row className="flow-column-reverse-mobile">
                    <Col xl={6} lg={6} md={24} className="imagesWrap">
                        <Image 
                            src={product} 
                            alt="Fabricação de compostos" 
                            preview={{
                                mask: (<label></label>),
                            }}
                        />
                    </Col>
                    <Col md={1}/>
                    <Col xl={11} lg={11}>
                        <ProductContent 
                            title="Fabricação de compostos" 
                            text="Produzimos compostos de acordo as necessidades e especificações de nossos clientes." 
                        />
                    </Col>
                    <Col md={6}/>
                </Row>
            </Container>
        </section>
    )
}

export default FabricacaoDeCompostos 