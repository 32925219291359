import { Card } from "antd";

const ProductCard = ({ product }) => {
    const { icon, title, link, mobileWidth50} = product;
    return(
        <a href={`/produtos/${link}`} className={['cardLink', mobileWidth50 && 'mobileWidth'].join(" ")}>
            <Card className="cardBox">
                <img src={icon} alt='Ícone de produto'/>
                <p>{title}</p>
            </Card>
        </a>
    )
}

export default ProductCard