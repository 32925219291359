import { Button } from "antd"
import { useNavigate } from "react-router"

const ProductContent = ({ title, text, fullContentBtn, link }) => {
    
    const navigate = useNavigate()
    const onClick = () => {
        navigate('/contato')
    }

    const openLink = (link) => {
        window.open(link)
    }

    return (
        <>
            <h2>{title}</h2>
            <p>{text}</p>
            <div className="buttonsWrapper">
                <Button onClick={onClick} type="primary" className="button">Quero entrar em contato</Button>
                {fullContentBtn && <Button type="secondary" className="button secondaryButton" onClick={()=>openLink(link)}>Acessar conteúdo completo</Button>}
            </div>  
        </>
    )
}

export default ProductContent