import { Container, ProductContent } from "../../components"
import { Row, Col, Image } from 'antd'
import Perfis from "./perfis/pefis"
import img1 from '../../assets/img/guarnicoes/guarni1.png'
import img2 from '../../assets/img/guarnicoes/guarni2.png'
import img3 from '../../assets/img/guarnicoes/guarni3.png'
import img4 from '../../assets/img/guarnicoes/guarni4.png'
// import img5 from '../../assets/img/guarnicoes/guarni5.png'
// import img6 from '../../assets/img/guarnicoes/guarni6.png'

const Guarnicoes = () => {

    const images = [img1, img2, img3, img4]

    return (
        <>
            <section className="mb-100" >
                <div className="breadcrumb">
                    <p>Produtos</p>
                    <hr/>
                </div>
                <Container>
                    <Row className="flow-column-reverse-mobile">
                        <Col xl={12} lg={12} md={24} className="imagesWrap imagesWrap2">
                            {images.map(i=> (
                                <Image 
                                    src={i} 
                                    alt="Imagem produto guarnições" 
                                    preview={{
                                        mask: (<label></label>),
                                    }}
                                />
                            ))}
                        </Col>
                        <Col md={1}/>
                        <Col xl={11} lg={11}> 
                            <ProductContent 
                                title="Guarnições EPDM e Silicone" 
                                text="Fabricamos uma ampla variedade de guarnições destinadas ao mercado de construção civil e automobilístico, sempre utilizando materiais de alta qualidade e que atendam as normas de
                                cada um dos setores. 
                                Temos uma linha completa em silicone para o fechamento de sacadas, garantindo vedação e
                                proteção eficientes. Possuímos ferramentaria própria, equipe de engenharia e desenvolvimento de projetos que nos permitem atender as necessidades de nossos clientes. Conte conosco para seu próximo projeto."
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="mb-100" >
                <Container>
                    <Row >
                        <Col xl={24} lg={24}>
                            <p className="d-flex justify-center mb-25 subtitle">Catálogo de guarnições</p>
                            <Perfis/>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Guarnicoes 
