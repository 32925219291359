import { Container } from '../../components'
import { Row, Col, Card } from 'antd'
import eye from '../../assets/img/icons/eye.svg'
import arrow from '../../assets/img/icons/arrow.svg'
import hands from '../../assets/img/icons/hands.svg'
import certification1 from '../../assets/img/certifications/certification1.png'
import certification2 from '../../assets/img/certifications/certification2.png'
import certification3 from '../../assets/img/certifications/certification3.png'
import certification4 from '../../assets/img/certifications/certification4.png'

const SobreNos = () => {
    const values = [
        {
            icon: eye,
            title: 'Visão',
            text: 'Ser a melhor opção de compra nos mercados em que atuamos',
            col: 6
        }, {
            icon: hands,
            title: 'Valores',
            text: '- Respeito a seus colaboradores, fornecedores, clientes e parceiros\t- Priorizar sempre pela máxima qualidade em seus produtos\n- Foco na satisfação de nossos clientes',
            col: 12
        }, {
            icon: arrow,
            title: 'Missão',
            text: 'Fornecer soluções em produtos e serviços com excelência em qualidade e satisfação ao cliente',
            col: 6
        }
    ]

    const certifications = [
        {
            icon: certification1,
            title: 'Anti-Chama IPT',
            file: 'Laudo-piso-classe-IIA-IPT.pdf'
        }, {
            icon: certification2,
            title: 'Guarnições em EPDM'
        }, {
            icon: certification3,
            title: 'NBR ISO 9001:2015',
            file: 'Certificado_ISO9001-Industria.pdf'
        }, {
            icon: certification4,
            title: 'IATF 16949:2016',
            file: 'Certificado_IATF_16949.pdf'
        }
    ]

    return(
        <>
            <Container>
                <Row className='mt-100'>
                    <Col xl={24}>
                        <div className='sobre-content-wrapper'>
                            <Row className='justify-center'>
                                <h1 className='sobre-title'>Nossa história</h1>
                                <Col xl={24} className='mb-10'>
                                    <p className='tx-center'>
                                    Fundada em 1935, a Daud consolidou sua história inicialmente ao atender a indústria automobilística. Hoje em dia, nossa empresa se destaca como fabricante de uma ampla gama de produtos, incluindo compostos de borracha, tapetes automotivos, pisos e revestimentos emborrachados, revestimentos acústicos e térmicos de borracha e poliuretano, guarnições para vedação de janelas, perfis para fechamento de sacadas e vedação de vidros automotivos.
                                    </p>
                                    <p className='tx-center'>
                                    Com uma área de 17 mil m², a Daud Borrachas possui controle total sobre todos os processos de fabricação. Nossa estrutura inclui laboratórios e análises próprios, o que nos permite garantir a qualidade em todas as etapas de produção.
                                    </p>
                                    <p className='tx-center'>
                                    Temos o compromisso de atender aos mais rigorosos certificados de qualidade e realizar testes em nossos produtos, assegurando a confiança dos nossos clientes.
                                    </p>
                                    <p className='tx-center tx-bold mt-50'>Clique nos ícones abaixo e acesso nossos certificados:</p>
                                </Col>
                                {certifications.map(certification => (
                                    <Col xl={6} lg={9} md={12} sm={12} xs={12} className='mt-25'>
                                        <a href={`/${certification.file}`} target='_blank' rel="noreferrer" className='d-flex flow-column align-center'>
                                            <img src={certification.icon} alt={certification.title} className='cursor-pointer'/>
                                            <h6 className='mt-10 text-center cursor-pointer'>{certification.title}</h6>
                                        </a>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row gutter={28} className='mt-50 mb-100'>
                    {values.map(value => (
                        <Col xl={value.col} lg={value.col} md={24} sm={24} xs={24}>
                            <Card className='card'> 
                                <center><img src={value.icon} alt={value.title} /></center>
                                <h4 className='tx-center tx-bold mt-10'>{value.title}</h4>
                                <p className='tx-center mt-10'>{value.text}</p>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export default SobreNos