import { Container, ProductContent } from "../../components"
import { Row, Col, Image } from 'antd'
import img1 from '../../assets/img/revestimentos/revest1.png'
import img2 from '../../assets/img/revestimentos/revest2.png'
import img3 from '../../assets/img/revestimentos/revest3.png'
import img4 from '../../assets/img/revestimentos/revest4.png'
import img5 from '../../assets/img/revestimentos/revest5.png'

const Revestimento = () => {
    const images = [img1, img2, img3, img4, img5]
    return (
        <section className="mb-100" >
            <div className="breadcrumb">
                <p>Produtos</p>
                <hr/>
            </div>

            <Container>
                <Row className="flow-column-reverse-mobile">
                    <Col xl={12} lg={12} md={24} className="imagesWrap imagesWrap3">
                        {images.map(i => (
                            <Image 
                                src={i} 
                                alt="Revestimentos acústicos" 
                                preview={{
                                    mask: (<label></label>),
                                }}
                            />
                        ))}
                    </Col>
                    <Col md={1}/>
                    <Col xl={11} lg={11}>
                        <ProductContent 
                            title="Revestimento acústico e térmico" 
                            text="Revestimento para interiores de cabines de caminhões feitos em borracha e PU." 
                        />
                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default Revestimento 